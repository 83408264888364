<template>
	<div class="modal fade" id="LocationSelector" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
		aria-hidden="true" data-backdrop="static">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<button type="button" v-if="show_close_button" class="close" data-backdrop="static" data-dismiss="modal"
					aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<div class="modal-body">
					<div class="bottom-section">
						<h2 class="section-heading text-center" v-if="globalsettings.length == 0">
							{{ local_platform_slogan }} </h2>
						<h2 class="section-heading text-center" v-else>{{ globalsettings.platform_slogan }} </h2>
						<form method="post" @submit.prevent="findStore">
							<div class="form-row">
								<div class="form-group col-12">
									<span v-if="customer_location_type == 1">
										<label for="findRestorant" class="sr-only restorant"></label>
										<vue-google-autocomplete ref="address" id="map" classname="form-control"
											placeholder="Please type your address" v-on:placechanged="getAddressData"
											@keypress.enter="$event.preventDefault()" v-model="formatted_address"
											types="" :country="country">
										</vue-google-autocomplete>
										<a href="javascript:void(0)"
											class="btn btn-clear mb-2 btn-clear-googleautocomplete"
											v-show="formatted_address != ''" @click="clearGoogleAutocomplete()">X</a>
									</span>
									<span v-if="customer_location_type == 3">
										<input
											:type="is_alpha_numeric == 1 || is_alpha_numeric == '1' ? 'text' : 'number'"
											name="pincode" class="form-control" id="pincode" v-model="customer_pincode"
											:placeholder="pincode_label_placeholder"
											:v-validate="customer_location_type == 3 ? 'required|alpha_num|digits:' + pincode_length + '' : ''"
											:maxlength="`${pincode_length}`" :minlength="`${pincode_length}`"
											data-vv-scope="pincodefindstore"
											:class="{ error: errors.items.filter(x => x.field == 'pincodefindstore').length > 0 }">
										<a href="javascript:void(0)" class="btn btn-clear mb-2 btn-clear-pincode"
											v-show="customer_pincode != ''" @click="clearPincode()">X</a>
										<span
											v-show="errors.items.filter(x => x.field == 'pincodefindstore').length > 0"
											class="help is-danger">Enter a valid {{ pincode_label }}</span>
									</span>
									<span v-if="customer_location_type == 2">
										<autocomplete ref="autocompletecustom" :initialValue="initValue"
											:initialDisplay="initValueDisplay" resultsValue="id" resultsDisplay="name"
											:source="custom_autocomplete_array" v-model="custom_formatted_address">
										</autocomplete>
									</span>
								</div>
							</div>
						</form>
						<button type="button" @click="pincodeValidation()" @mouseover="hoverColor()"
							@mouseout="originalColor()" :style="colorObj" class="btn find-store-button">Go to
							Store</button>
					</div>
					<p class="login-row" v-if="show_signup_link">Already have an account? <a href="javascript:void(0)"
							:style="textcolor" @click="openLoginfrm">Sign In/ Sign Up </a></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { bus } from '../../main';
import axios from "axios";
import helper from "../../store/helper"
import localconfig from "../../global_settings.json";
import Autocomplete from 'vuejs-auto-complete';
export default {
	name: 'LocationSelector',
	components: {
		'vue-google-autocomplete': VueGoogleAutocomplete,
		'autocomplete': Autocomplete
	},
	mixins: [helper],
	data: function () {
		return {
			initValueDisplay: '',
			initValue: '',
			address: {},
			formatted_address: '',
			pincode_label_placeholder: "",
			custom_formatted_address: '',
			customer_pincode: '',
			noLocation: true,
			coordinates: {},
			location_details: {
				lat: "",
				lng: "",
				address: "",
			},
			show_signup_link: false,
			show_close_button: false,
			globalsettings: [],
			isApicall: false,
			local_platform_slogan: "",
			country: "",
			colorObj: {
				backgroundColor: "",
				color: ""
			},
			textcolor: {
				color: ''
			},
			customer_location_type: 1,
			is_alpha_numeric: 0,
			pincode_length: 0,
			customer_location_value: "",
			custom_autocomplete_array: [],
			location_visited : false
		}
	},
	mounted() {
		if (localStorage.getItem("global_settings") == null) {
			this.getGlobalSettings();
		} else {
			this.globalsettings = JSON.parse(localStorage.getItem("global_settings"));
		}

		if (localStorage.getItem("user") == null) {
			this.show_signup_link = true;
		}
		bus.$on('logout', () => {
			this.show_signup_link = true;
		});

		this.$refs.address.focus();
		if (!(localStorage.getItem("location_visited")) && this.show_signup_link == true) {
			if (window.location.pathname == '/order-summary' || window.location.pathname == "/exclusive-deals") {
				window.$("#LocationSelector").modal("hide");
			} else {
				window.$("#LocationSelector").modal("show");
			}
		}
		this.location_visited = true
		localStorage.setItem("location_visited", this.location_visited)

	},
	methods: {
		async pincodeValidation() {
			if (this.customer_location_type == 3) {
				var pincodeData = await axios.get(
					"https://maps.googleapis.com/maps/api/geocode/json?address=" + this.customer_pincode +
					"&key=" + ((localStorage.getItem("google_map_key") != null && localStorage.getItem("google_map_key") != "" && localStorage.getItem("google_map_key") != undefined) ? localStorage.getItem("google_map_key") : 'AIzaSyDU1yuRqsrT2TFdn5srqr5F9iDzdDqzVL4')
				);
				this.pincode_length = localStorage.getItem("pincode_length");
				if (this.customer_pincode != '' && this.customer_pincode.length != this.pincode_length) {
					this.$toast.error("Enter " + this.pincode_label + " in correct format");
				}
				else if (pincodeData.data.results == null || pincodeData.data.results == undefined || pincodeData.data.results.length <= 0) {
					this.$toast.error("We are sorry.We currently do not deliver to this location");
				}
				else {
					localStorage.removeItem("address_set_type")
					this.findStore();
				}
			}
			else {
				localStorage.removeItem("address_set_type")
				this.findStore();
			}
		},
		clearGoogleAutocomplete() {
			this.formatted_address = '';
			window.$("#map").val("")
		},
		clearPincode() {
			this.customer_pincode = '';
		},
		hoverColor() {
			this.colorObj.backgroundColor = localStorage.getItem("button_hover_color")
			this.colorObj.color = localStorage.getItem("css_button_customer_hover_font")
		},
		originalColor() {
			this.colorObj.backgroundColor = localStorage.getItem("button_default_color")
			this.colorObj.color = localStorage.getItem("css_button_customer_default_font")
		},
		async getLocation() {
			try {
				const coordinates = await this.$getLocation({
					enableHighAccuracy: false
				}).then(coordinates => {
					localStorage.removeItem('location_details');
					this.getStreetAddressFrom(coordinates.lat, coordinates.lng);
				});
				this.coordinates = coordinates;
				this.noLocation = false;
			} catch (error) {
				this.noLocation = true;
			}
		},
		// async getStreetAddressFrom(lat, long) {
		// 	try {
		// 		var {
		// 			data
		// 		} = await axios.get(
		// 		"https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
		// 		lat +
		// 		"%2C" +
		// 		long +
		// 		"&key="+((localStorage.getItem("google_map_key")!=null && localStorage.getItem("google_map_key")!="" && localStorage.getItem("google_map_key")!=undefined) ? localStorage.getItem("google_map_key") : 'AIzaSyDU1yuRqsrT2TFdn5srqr5F9iDzdDqzVL4')
		// 		);
		// 		if (data.error_message) {
		// 			console.log(data.error_message)
		// 		} else {
		// 			this.formatted_address = data.results[0].formatted_address;
		// 			if (this.formatted_address != "" && this.formatted_address != undefined)
		//                this.show_close_button = false;
		// 			this.location_details.lat = lat;
		// 			this.location_details.lng = long;
		// 			this.location_details.address = this.formatted_address;
		// 			this.customer_pincode = data.results[0].address_components[data.results[0].address_components.length - 1].long_name;
		// 			localStorage.setItem("location_details", JSON.stringify(this.location_details));
		// 			console.log(this.location_details, "location details header1");
		// 		}
		// 	} catch (error) {
		// 		console.log(error.message);
		// 	}
		// },

		async getStreetAddressFrom(lat, long) {
			try {
				var {
					data
				} = await axios.get(
					"https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
					lat +
					"%2C" +
					long +
					"&key=" + ((this.global_settings.google_map_key != "") ? this.global_settings.google_map_key : 'AIzaSyAfPTScypN43sAUdwCJbiD5HFPY5mS0G1M')
				);
				if (data.error_message) {
					console.log(data.error_message)
				} else {
					let returnData = {};
					//   const ADDRESS_COMPONENTS = {
					//       subpremise : 'short_name',
					//       street_number: 'short_name',
					//       route: 'long_name',
					//       locality: 'long_name',
					//       administrative_area_level_1: 'long_name',
					//       administrative_area_level_2: 'long_name',
					//       country: 'long_name',
					//       postal_code: 'short_name'
					//   };

					for (let i = 0; i < data.results[0].address_components.length; i++) {
						let addressType = data.results[0].address_components[i].types[0];
						if (addressType == "postal_code" && addressType == "country") {
							let val = data.results[0].address_components[i]["short_name"];
							returnData["postal_code"] = val;
						}
					}
					this.customer_pincode_hidden = returnData["postal_code"];
					if (this.customer_pincode_hidden != undefined) {
						localStorage.setItem("pincode", this.customer_pincode_hidden);
					}
					localStorage.setItem("country", data.plus_code.compound_code.split(" ")[3]);
					var tax_pincode = data.results[7].formatted_address.split(" ")[2];
					tax_pincode = tax_pincode.replace(',', '');
					localStorage.setItem("tax_pincode", tax_pincode);
					this.formatted_address = data.results[0].formatted_address;
					if (this.formatted_address != "" && this.formatted_address != undefined)
						this.show_close_button = false;
					this.location_details.lat = lat;
					this.location_details.lng = long;
					this.location_details.address = this.formatted_address;
					this.customer_pincode = data.results[0].address_components[data.results[0].address_components.length - 1].long_name;
					localStorage.setItem("location_details", JSON.stringify(this.location_details));
				}
			} catch (error) {
				console.log(error.message);
			}
		},
		// getAddressData: function (addressData) {
		// 	console.log(addressData, "addressdata from get address data");
		// 	this.address = addressData;
		// 	this.location_details.lat = this.address.latitude;
		// 	this.location_details.lng = this.address.longitude;
		// 	this.location_details.address = this.address.formatted_address;
		// 	this.formatted_address = this.address.formatted_address;
		// 	localStorage.setItem("location_details", JSON.stringify(this.location_details));
		// 	console.log(this.location_details, "location details header2");
		// 	console.log(this.formatted_address, "this.formatted_address");

		// },
		getAddressData: function (addressData) {
			this.temp_pincode = '';
			this.address = addressData;
			if (this.address != undefined) {
				this.location_details.lat = this.address.latitude;
				this.location_details.lng = this.address.longitude;
				console.log('set address1', this.address);
				this.location_details.address = this.address.locality != undefined ? this.address.locality : this.address.country;
				//this.location_details.address = this.address.country;
				this.location_details.zipcode = this.address.postal_code;
				this.location_details.country = this.address.country;
				this.formatted_address = this.location_details;
				// this.formatted_address = this.address.formatted_address;
				this.location_details.customer_address_id = "";
				console.log('set address2', this.location_details);
				localStorage.setItem("location_details", JSON.stringify(this.location_details));
				//this.temp_pincode = this.address.postal_code;
				localStorage.setItem("country", this.address.country);
				if (this.address.postal_code != undefined) {
					localStorage.setItem("pincode", this.address.postal_code);
					localStorage.setItem("tax_pincode", this.address.postal_code);
				}
			}
		},
		// findStore() {
		// 	console.log(this.formatted_address, "find store functtion");
		// 	console.log(this.customer_location_type, "this.customer_location_type");
		// 	if (this.customer_location_type == 3) {
		// 		this.location_details.address = this.customer_pincode;
		// 		this.formatted_address = this.customer_pincode;

		// 		localStorage.setItem("config_customer_pincode", this.location_details.address);
		// 		localStorage.setItem("location_details", JSON.stringify(this.location_details));
		// 		console.log(this.location_details, "location details header3");

		// 	}
		// 	else if (this.customer_location_type == 2) {
		// 		var custObj = this.custom_autocomplete_array.filter(x => x.id == this.custom_formatted_address);
		// 		if (custObj != null && custObj != undefined && custObj.length > 0) {
		// 			this.location_details.address = custObj[0].name;
		// 			this.formatted_address = custObj[0].name;
		// 		}
		// 		localStorage.setItem("location_details", JSON.stringify(this.location_details));
		// 		localStorage.setItem("config_area_id", this.custom_formatted_address);
		// 		console.log(this.location_details, "location details header4");

		// 	}
		// 	else {
		// 		console.log(this.location_details.address, "else case location selecter");
		// 		this.location_details.address = this.formatted_address;
		// 	}

		// 	var year = 1000 * 60 * 60 * 24 * 365;
		// 	var expires = new Date((new Date()).valueOf() + year);
		// 	document.cookie = "visited=true;expires=" + expires.toUTCString();
		// 	if (localStorage.getItem("location_details") == null || localStorage.getItem("location_details") == undefined) {
		// 		localStorage.setItem("location_details", JSON.stringify(this.location_details));
		// 		console.log(this.location_details, "location details header5");

		// 	}
		// 	window.location.href = "/";
		// 	window.location.reload();
		// },

		findStore() {
			localStorage.setItem("set_address", 1);
			if (this.customer_location_type == 3) {
				localStorage.setItem("set_address", 1);
				this.location_details.address = this.formatted_address;
				//this.formatted_address = this.customer_pincode;

				if (this.formatted_address == '' || window.$("#map").val() == "") {
					this.$toast.error('Please select location from suggestions');
					return false;
				}
				if (this.address != undefined) {
					if (localStorage.getItem("user") != null) {
						this.location_details.customer_address_id = "";
						localStorage.removeItem("config_customer_pincode");
						localStorage.setItem("prelogin_setlocation", 0);
						localStorage.setItem("set_from_location_selector", 1);
						localStorage.setItem("country", this.address.country)
						this.formatted_address = this.address.formatted_address;
					} else {
						localStorage.setItem("config_customer_pincode", this.location_details.address);
						localStorage.setItem("prelogin_setlocation", 1);
						localStorage.setItem("temp_pincode", localStorage.getItem("pincode"));
					}

					localStorage.setItem("location_details", JSON.stringify(this.location_details));
				}
			}
			else if (this.customer_location_type == 2) {
				var custObj = this.custom_autocomplete_array.filter(x => x.id == this.custom_formatted_address);
				if (custObj != null && custObj != undefined && custObj.length > 0) {
					this.location_details.address = custObj[0].name;
					this.formatted_address = custObj[0].name;
				}
				localStorage.setItem("location_details", JSON.stringify(this.location_details));
				localStorage.setItem("config_area_id", this.custom_formatted_address);
			}
			else {
				localStorage.setItem("from_google_suggestion", 1);
				this.location_details.address = this.formatted_address;
			}
			var year = 1000 * 60 * 60 * 24 * 365;
			var expires = new Date((new Date()).valueOf() + year);
			document.cookie = "visited=true;expires=" + expires.toUTCString();
			if (localStorage.getItem("location_details") == null || localStorage.getItem("location_details") == undefined) {
				localStorage.setItem("location_details", JSON.stringify(this.location_details));
			}
			window.location.href = "/";
			window.location.reload();
		},
		openLoginfrm() {
			window.$('#LocationSelector').modal('hide');
			window.$("#login-form").modal("show");
			window.$("#VerificationFields").removeClass("show");
			window.$("#SignupFields").removeClass("show");
			window.$("#ChangePassword").removeClass("show");
			window.$("#loginPopupFields").addClass("show");
		},
		async getGlobalSettings() {
			return this.$store.dispatch("getglobalsetng").then(response => {
				if (response.status == 1) {
					this.globalsettings = response.data;
					if (localStorage.getItem("location_details") != null && localStorage.getItem("location_details") != undefined) {
						this.location_details.address = JSON.parse(localStorage.getItem("location_details")).address;
						this.customer_pincode = JSON.parse(localStorage.getItem("location_details")).address;
					}
					else {
						this.location_details.address = this.globalsettings.default_location_text;
						this.customer_pincode = this.globalsettings.default_location_text;
					}

					this.location_details.lat = this.globalsettings.default_location_lat;
					this.location_details.lng = this.globalsettings.default_location_long;

					this.customer_location_type = this.globalsettings.customer_location_type;
					localStorage.setItem("customer_location_type", this.customer_location_type);
					this.pincode_label = this.globalsettings.pincode_label;
					this.pincode_label_placeholder = "Enter your " + this.globalsettings.pincode_label;
					if (this.globalsettings.store_areas != null && this.globalsettings.store_areas.length > 0) {
						this.custom_autocomplete_array = [];
						for (var i = 0; i < this.globalsettings.store_areas.length; i++) {
							var tempAreaCityObj = {};
							tempAreaCityObj["id"] = this.globalsettings.store_areas[i].area_id;
							tempAreaCityObj["name"] = this.globalsettings.store_areas[i].area_name + ", " + this.globalsettings.store_areas[i].city_name + "";
							this.custom_autocomplete_array.push(tempAreaCityObj);
						}
						if (this.customer_location_type == 2 && ((localStorage.getItem("location_details") == null || localStorage.getItem("location_details") == undefined) && this.custom_formatted_address == "")) {
							this.initValue = this.globalsettings.default_location_text;
							this.custom_formatted_address = this.initValue;
							var custObj = this.custom_autocomplete_array.filter(x => x.id == parseFloat(this.initValue))
							if (custObj != null && custObj != undefined) {
								this.initValueDisplay = custObj[0].name;
							}
						}
						else {
							if (localStorage.getItem("location_details") != null && localStorage.getItem("location_details") != undefined) {
								var custObjIf = this.custom_autocomplete_array.filter(x => x.name == JSON.parse(localStorage.getItem("location_details")).address)
								if (custObjIf != null && custObjIf != undefined && this.customer_location_type == 2 && custObjIf.length > 0) {
									this.initValueDisplay = custObjIf[0].name;
								}
							}
							else {
								var custObjElse = this.custom_autocomplete_array.filter(x => x.id == parseFloat(this.globalsettings.default_location_text))
								if (custObjElse != null && custObjElse != undefined && this.customer_location_type == 2 && custObjElse.length > 0) {
									this.initValueDisplay = custObjElse[0].name;
								}
							}
						}
					}

					if (localStorage.getItem("location_details") == null && this.customer_location_type != 2) {
						this.getLocation();
					} else {
						if (localStorage.getItem("location_details") != null && localStorage.getItem("location_details") != undefined) {
							this.formatted_address = JSON.parse(localStorage.getItem("location_details")).address;
							if (this.formatted_address != "" && this.formatted_address != undefined)
								this.show_close_button = true;
						}
					}

					this.formatted_address = JSON.parse(localStorage.getItem("location_details")) == null ? this.location_details.address : JSON.parse(localStorage.getItem("location_details")).address;
					localStorage.setItem("google_map_key", this.globalsettings.google_map_key);
					localStorage.setItem("title", this.globalsettings.platform_page_title);
					this.country = this.globalsettings.country_codes;
					if (this.country != null && this.country != undefined && this.country == "") {
						this.country = localconfig.country_codes;
					}
					if (this.colorObj.backgroundColor != "" && this.colorObj.color != '') {
						this.colorObj.backgroundColor = localconfig.css_button_customer_default
						this.colorObj.color = localconfig.css_button_customer_default_font
					} else {
						this.colorObj.backgroundColor = this.globalsettings.css_button_customer_default
						this.colorObj.color = this.globalsettings.css_button_customer_default_font
					}
					this.textcolor.color = this.textcolor.color == "" ? this.globalsettings.css_button_customer_default : localconfig.css_button_customer_default;
					this.pincode_length = this.globalsettings.pincode_length;
					this.is_alpha_numeric = this.globalsettings.allow_alphanumeric_pincode;
				} else {
					this.local_platform_slogan = localconfig.platform_slogan
					localStorage.setItem("title", localconfig.platform_page_title);
					this.location_details.address = localconfig.default_location_text;
					this.location_details.lat = localconfig.default_location_lat;
					this.location_details.lng = localconfig.default_location_long;
					this.customer_location_type = localconfig.customer_location_type;
					this.formatted_address = JSON.parse(localStorage.getItem("location_details")) == null ? this.location_details.address : JSON.parse(localStorage.getItem("location_details")).address;
					this.country = localconfig.country_codes;
					this.colorObj.backgroundColor = localconfig.css_button_customer_default
					this.colorObj.color = localconfig.css_button_customer_default_font
					this.textcolor.color = this.textcolor.color == "" ? localconfig.css_button_customer_default : this.globalsettings.css_button_customer_default
				}
				this.country = this.country.split(",");
			})
		}
	}
}
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}
</style>