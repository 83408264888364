<template>
  <div class="modal fade login-popup" id="login-form" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" ref="login_popup">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="bottom-section">
          <div class="modal-body">
            <div class="login-form-popup" v-show="loading == false">
              <div class="popup-fields show" id="loginPopupFields">
                <form class="animated-form" method="post" @submit.prevent="validateBeforeSubmit">
                  <div class="fieldset">
                    <div class="form-row">
                      <div class="col-md-12 form-group">
                        <h2 class="section-heading text-center">Sign In / Sign Up</h2>
                      </div>
                    </div>
                    <div class="alert alert-success" role="alert" v-show="enablesucmessage">{{ successmessage }}</div>

                    <div v-if="login_mode == 1" class="form-row">
                      <div class="form-group col-md-12 active">
                        <label :style="textcolor" for="mobilenumber">
                          Mobile Number
                          <span class="helpmsg">*</span>
                        </label>
                        <input type="text" class="form-control" name="mobilenumber" id="mobilenumber"
                          v-model="mobilenumber" v-validate="'required|digits:10'" data-vv-scope="mobilenumberlogin"
                          data-vv-rules="required|digits:10" data-vv-as="mobilenumber"
                          :class="{ error: errors.items.filter(x => x.field == 'mobilenumber').length > 0 }"
                          maxlength="10" ref="mobile_number">
                        <span v-show="errors.items.filter(x => x.field == 'mobilenumber').length > 0"
                          class="help is-danger">Enter a valid mobile number</span>
                      </div>
                    </div>
                    <div v-if="login_mode == 2" class="form-row">
                      <div class="form-group col-md-12 active">
                        <label :style="textcolor" for="emailaddress">
                          Email Address
                          <span class="helpmsg">*</span>
                        </label>
                        <input type="text" class="form-control" name="emailaddress" id="emailaddress"
                          v-model="emailaddress" v-validate="'required|email'" data-vv-scope="emailaddresslogin"
                          data-vv-rules="required|email" data-vv-as="emailaddress"
                          :class="{ error: errors.items.filter(x => x.field == 'emailaddress').length > 0 }"
                          ref="email_address">
                        <span v-show="errors.items.filter(x => x.field == 'emailaddress').length > 0"
                          class="help is-danger">Enter a valid email address</span>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group form-check col-md-8" v-if="login_mode == 1">
                        <input type="checkbox" id="agreeterm" name="agreeterm" v-model="agreeterm" value="0"
                          v-validate="'required'"
                          :class="{ error: errors.items.filter(x => x.field == 'agreeterm').length > 0 }"
                          data-vv-scope="mobilenumberlogin">
                        <label class="form-check-label lbl-agreeterm" for="agreeterm">
                          I agree to the
                          <a :href="termsOfUseUrl" target="_blank">Terms of Use</a>
                        </label>
                        <span v-show="errors.items.filter(x => x.field == 'agreeterm').length > 0"
                          class="help is-danger helperrmesg">Agree to Terms of Use</span>
                      </div>
                      <div class="form-group form-check col-md-8" v-if="login_mode == 2">
                        <input type="checkbox" id="agreeterm" name="agreeterm" v-model="agreeterm" value="0"
                          v-validate="'required'"
                          :class="{ error: errors.items.filter(x => x.field == 'agreeterm').length > 0 }"
                          data-vv-scope="emailaddresslogin">
                        <label class="form-check-label lbl-agreeterm" for="agreeterm">
                          I agree to the
                          <a :href="termsOfUseUrl" target="_blank">Terms of Use</a>
                        </label>
                        <span v-show="errors.items.filter(x => x.field == 'agreeterm').length > 0"
                          class="help is-danger helperrmesg">Agree to Terms of Use</span>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group text-center col-12">
                        <div class="col-12">
                          <button type="submit" :style="buttonColor"
                            class="btn button-style hoverEffect">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="popup-fields" id="VerificationFields">
                <form name="frmverfication" id="frmverfication" class="animated-form" method="post"
                  @submit.prevent="VerifyCode">
                  <div class="fieldset">
                    <div class="alert alert-success" id="verifysucsmsg" role="alert"
                      v-if="verifyotpsucsmesage != '' && verifyotpstatus == '1'">{{ verifyotpsucsmesage }}</div>
                    <div class="alert alert-danger" id="verifyerrormsg" role="alert"
                      v-if="verifyotperrmessage != '' && verifyotpstatus == '0'">{{ verifyotperrmessage }}</div>

                    <div class="form-row">
                      <div class="col-md-12 form-group">
                        <h2 class="section-heading text-center">Verification</h2>
                        <p class="text-center" v-if="login_mode == 1">Code sent to verify mobile number</p>
                        <p class="text-center" v-if="login_mode == 2">Code sent to verify email address</p>
                        <p class="text-center">
                          Enter your OTP code
                          <span v-if="timeLeft != '00:00'"> (<span :style="textcolor" class="cl-sky-blue">{{ timeLeft
                              }}</span> mins)</span>
                        </p>
                      </div>
                    </div>
                    <div class="otp-row">
                      <v-otp-input inputClasses="otp-input" :numInputs="4" separator="-" :shouldAutoFocus="true" />
                    </div>
                    <div class="form-row mt-auto">
                      <div class="form-group col-md-12">
                      </div>
                      <div class="form-group col-md-12 text-center" v-if="timeLeft != '00:00'">
                        {{ OtpSentConfirmation }}
                      </div>
                      <div class="form-group col-md-12 text-center" v-if="timeLeft == '00:00'">
                        <p>I didn’t receive a code</p>
                        <a href="javascript:void(0)" :style="textcolor" @click="Resendcode">Resend Code</a>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group text-center col-12">
                        <div class="col-12">
                          <button type="submit" class="btn button-style hoverEffect" :style="colorObj"
                            id="VerificationSubmit">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row" v-show="loading == true">
              <div class="container">
                <p class="text-center">
                  <img src="../../assets/images/loading.gif" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
function zeroPadded(num) {
  // 4 --> 04
  return num < 10 ? `0${num}` : num;
}

function hourConvert(hour) {
  // 15 --> 3
  return hour % 12 || 12;
}
import { bus } from "../../main";
import OtpInput from "@bachdgvn/vue-otp-input";
import helper from "../../store/helper";
import localconfig from "../../global_settings.json";
import firebase from 'firebase/app';
var intervalTimer;
export default {
  name: "Login",
  components: {
    "v-otp-input": OtpInput
  },
  mixins: [helper],
  data() {
    return {
      loading: true,
      selectedTime: 0,
      timeLeft: "00:00",
      endTime: "0",
      resendcodeflg: false,
      mobilenumber: "",
      password: "",
      errormessage: "",
      successmessage: "",
      status: "",
      agreeterm: "",
      enablesucmessage: false,
      loginbody: {
        phone: "",
        otp: "",
        fcm_token: ""
      },
      resetpassbody: {
        phone: ""
      },
      verifyotpbody: {
        phone: "",
        otp: ""
      },
      verify_otp: {
        user_id: "",
        otp: "",
      },
      profile: {
        user_id: "",
      },
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      finalotp: "",
      verifyotpsucsmesage: "",
      verifyotperrmessage: "",
      verifyotpstatus: "",
      responseverfiyotp: "",
      passwordFieldType: "password",
      OtpSentConfirmation: "",
      termsOfUseUrl: "",
      login_mode: "",
      emailaddress: "",
      firebaseuuid: 0,
      is_header_icons_show: true,
      email_verified_data: "",
      verify_email: {
        user_id: '',
        source: '',
      },
      set_time: "",
    };
  },
  mounted() {
    this.login_mode = window.location.pathname == '/profile' ? 2 : 1,
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    this.loading = false;
    window.$(this.$refs.login_popup).on("show.bs.modal", this.onModalShow);
    window.$(this.$refs.login_popup).on("hide.bs.modal", this.onModalHide);
    this.setTime(60);
    this.termsOfUseUrl = localStorage.getItem("terms_of_use_url");
    window.$('<style>.animated-form .active .form-control { border-color: ' + localStorage.getItem("button_default_color") + ' !important }</style>').appendTo('body');
    bus.$on("timer", data => {
      this.set_time = data
      this.setTime(60);
      this.ResetOtp();
      // this.validateBeforeSubmit();
    });
    if (window.location.pathname != '/profile') {
      bus.$on("globalsetting", data => {
        if (Object.keys(data).length != 0) {
          this.login_mode = JSON.parse(data).login_using;
        } else {
          this.login_mode = localconfig.login_using;
        }
      });
    } else {
      this.login_mode = 2;
    }


    this.originalColor();

  },
  methods: {
    onModalHide() {
      localStorage.setItem("mobilenumber", this.loginbody.phone);
      this.mobilenumber = "";
      this.agreeterm = false;
      this.$validator.reset();
    },
    onModalShow() {
      setTimeout(() => (this.$refs.mobile_number.focus()), 1000);
    },
    setTime(seconds) {
      clearInterval(intervalTimer);
      this.timer(seconds);
    },
    timer(seconds) {
      const now = Date.now();
      const end = now + seconds * 1000;
      this.displayTimeLeft(seconds);

      this.selectedTime = seconds;
      this.displayEndTime(end);
      this.countdown(end);
    },
    countdown(end) {
      intervalTimer = setInterval(() => {
        const secondsLeft = Math.round((end - Date.now()) / 1000);

        if (secondsLeft === 0) {
          this.endTime = 0;
        }

        if (secondsLeft < 0) {
          clearInterval(intervalTimer);
          return;
        }
        this.displayTimeLeft(secondsLeft);
      }, 1000);
    },
    displayTimeLeft(secondsLeft) {
      const minutes = Math.floor((secondsLeft % 3600) / 60);
      const seconds = secondsLeft % 60;

      this.timeLeft = `${zeroPadded(minutes)}:${zeroPadded(seconds)}`;
    },
    displayEndTime(timestamp) {
      const end = new Date(timestamp);
      const hour = end.getHours();
      const minutes = end.getMinutes();

      this.endTime = `${hourConvert(hour)}:${zeroPadded(minutes)}`;
    },
    Resendcode() {
      if (this.login_mode == 1) {
        this.$store
          .dispatch("resetpassword", this.resetpassbody)
          .then(res => {
            this.loading = false;
            this.successmessage = JSON.parse(JSON.stringify(res)).message;
            this.enablesucmessage = true;
            setTimeout(() => (this.enablesucmessage = false), 3000);
            this.enablesucmessage = false;
            if (this.enablesucmessage == false) {
              // if (this.login_mode == 2) {
              this.ResetOtp();
              this.OtpSentConfirmation = "New OTP Code sent to verify mobile number";
              // }
              // else {
              //   console.log("for email");
              //   this.ResetOtp();
              //   this.OtpSentConfirmation = "New OTP Code sent to verify mobile number";
              // }
            }
          });
      } else {
        this.setTime(60);
        this.ResetOtp();
        this.OtpSentConfirmation = "New OTP Code sent to verify email address";
        this.verify_email.source = 'web';
        this.verify_email.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
        this.$store.dispatch("verifyEmailAddress", this.verify_email).then(response => {
          if (response.status == 1) {
            this.$toast.success(response.message);

          }
        });
      }
      this.setTime(60);
      this.resendcodeflg = true;
    },
    ResetOtp() {
      window.$('.otp-input').eq(0).val("")
      window.$('.otp-input').eq(1).val("")
      window.$('.otp-input').eq(2).val("")
      window.$('.otp-input').eq(3).val("")
    },
    getProfile() {
      this.profile.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.$store.dispatch("profiledetails", this.profile).then(response => {
        if (response.status == 1) {
          this.email_verified_data = response.data;
          bus.$emit("email_verified_data", this.email_verified_data.is_email_verified);
        }
      });
    },
    VerifyCode() {
      this.verifyotpbody.phone = this.mobilenumber;
      this.finalotp = window.$('.otp-input').eq(0).val() + window.$('.otp-input').eq(1).val() + window.$('.otp-input').eq(2).val() + window.$('.otp-input').eq(3).val();
      if (this.finalotp.length != 4) {
        this.verifyotperrmessage = "Please enter required field";
        this.verifyotpstatus = 0;
        window.$(".alert-danger").fadeIn().delay(3000).fadeOut();
        this.ResetOtp();
        return false;
      }
      this.verifyotpbody.otp = this.finalotp;
      if (this.login_mode == 1) {
        if (this.status == "3") {
          this.loading = true;
          this.loginbody.phone = this.mobilenumber;
          this.loginbody.otp = this.finalotp;
          this.loginbody.fcm_token = localStorage.getItem("fcm_token");
          this.$store.dispatch("login", this.loginbody).then(response => {
            this.loading = false;
            this.checkCustomer = JSON.parse(localStorage.getItem("user"))

            if (response.status == "1") {
              if (this.checkCustomer.is_confirm == 0) {
                this.is_header_icons_show = false,
                  bus.$emit("is_header_icons_show", this.is_header_icons_show);
                window.$("#login-form").modal("hide");
                localStorage.setItem("mobilenumber", this.loginbody.phone);
                localStorage.setItem("email", this.checkCustomer.email);
                localStorage.setItem("name", this.checkCustomer.name);
                localStorage.setItem("address2", this.checkCustomer.address2);
                localStorage.setItem("address", this.checkCustomer.address);

                this.$router.push("/signup");
                return;
              } else {
                this.is_header_icons_show = true,
                  bus.$emit("is_header_icons_show", this.is_header_icons_show)
                window.$("#login-form").modal("hide");
                bus.$emit("changeIt", JSON.parse(localStorage.getItem("user")));

                localStorage.setItem("is_login", true);
                this.firebaseCustomerAuth();

                if (window.location.href.indexOf("productdetail") > -1 || window.location.href.indexOf("shopdetail") > -1) {
                  localStorage.setItem("address_changed", "1");
                }
                if (localStorage.getItem("LastUrlBeforeLogin") != null && localStorage.getItem("LastUrlBeforeLogin") != undefined) {
                  if (localStorage.getItem("SelectedProductBeforeLogin") != null && localStorage.getItem("SelectedProductBeforeLogin") != undefined) {
                    window.location.href = 'productdetail?itemid=' + localStorage.getItem("SelectedProductBeforeLogin");
                    window.location.reload();
                  }
                  else {
                    window.location.href = localStorage.getItem("LastUrlBeforeLogin");
                    window.location.reload();
                  }
                  localStorage.removeItem("SelectedProductBeforeLogin")
                  localStorage.removeItem("LastUrlBeforeLogin");
                }
                else
                  window.location = window.location.href;
                window.location.reload();
              }
            } else {
              this.ResetOtp();
              let messag = JSON.parse(JSON.stringify(response)).message;
              if (messag == "Enter all required fields") {
                this.verifyotperrmessage = "Please enter required field";
                this.verifyotpstatus = JSON.parse(JSON.stringify(response)).status;
                window.$(".alert-danger").fadeIn().delay(3000).fadeOut();
              } else if (messag == "Enter correct OTP") {
                this.verifyotperrmessage = "Please enter correct OTP";
                this.verifyotpstatus = JSON.parse(JSON.stringify(response)).status;
                window.$(".alert-danger").fadeIn().delay(3000).fadeOut();
              } else {
                this.verifyotperrmessage = JSON.parse(JSON.stringify(response)).message;
                this.verifyotpstatus = JSON.parse(JSON.stringify(response)).status;
                window.$(".alert-danger").fadeIn().delay(3000).fadeOut();
              }
            }
          });
        } else {
          this.loading = true;
          this.$store
            .dispatch("verficationotp", this.verifyotpbody)
            .then(response => {
              this.loading = false;
              if (this.status == "3") {
                if (JSON.parse(JSON.stringify(response)).status == "1") {
                  this.verifyotpsucsmesage = JSON.parse(JSON.stringify(response)).message;
                  this.verifyotpstatus = JSON.parse(JSON.stringify(response)).status;
                  window.$("#verifysucsmsg").fadeIn().delay(3000).fadeOut();
                } else {
                  this.verifyotperrmessage = JSON.parse(JSON.stringify(response)).message;
                  this.verifyotpstatus = JSON.parse(JSON.stringify(response)).status;
                  window.$(".alert-danger").fadeIn().delay(3000).fadeOut();
                }
              } else if (JSON.parse(JSON.stringify(response)).status == "1") {
                window.$("#login-form").modal("hide");
                localStorage.setItem("mobilenumber", this.loginbody.phone);
                this.$router.push("/signup");
              }
              else if (JSON.parse(JSON.stringify(response)).status == "0") {
                this.verifyotperrmessage = JSON.parse(JSON.stringify(response)).message;
                this.verifyotpstatus = JSON.parse(JSON.stringify(response)).status;
                window.$(".alert-danger").fadeIn().delay(3000).fadeOut();
              }
            });
        }
      } else {
        this.loading = true;
        this.verify_otp.otp = this.finalotp;
        this.verify_otp.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
        this.$store.dispatch("emailvarificationotp", this.verify_otp).then(response => {
          this.loading = false;
          if (response.status == 1) {
            this.$toast.success(response.message);
            window.$("#login-form").modal("hide");
            this.getProfile();
          } else {
            this.$toast.error(response.message);
            this.ResetOtp();
          }
        });
      }

      this.finalotp = this.otp1 + this.otp2 + this.otp3 + this.otp4;

      this.otp1 = null;
      this.otp2 = null;
      this.otp3 = null;
      this.otp4 = null;
      this.finalotp = null;
      this.agreeterm = false;
      this.$validator.reset();
    },
    validateBeforeSubmit() {
      if (this.login_mode == 1) {
        this.$validator.validateAll('mobilenumberlogin').then(result => {
          if (result) {
            this.loading = true;
            this.loginbody.phone = this.mobilenumber;
            this.$store.dispatch("register", this.loginbody).then(response => {
              if (response) {
                if (response.message != null && response.status == "0") {
                  this.errormessage = response.message;
                  this.loading = false;
                } else {
                  this.status = response.status;
                  if (response.status == "3") {
                    this.resetpassbody.phone = this.mobilenumber;
                    this.$store
                      .dispatch("resetpassword", this.resetpassbody)
                      .then(res => {
                        this.loading = false;
                        this.successmessage = JSON.parse(JSON.stringify(res)).message;
                        this.enablesucmessage = true;
                        setTimeout(() => (this.enablesucmessage = false), 3000);
                        this.enablesucmessage = false;
                        if (this.enablesucmessage == false) {
                          window.$("#loginPopupFields").removeClass("show");
                          window.$("#VerificationFields").addClass("show");
                          //setTimeout(() => (this.$refs.otp_1.focus()), 1000);
                          this.setTime(60);
                          this.resendcodeflg = true;
                        }
                      });
                  } else {
                    this.successmessage = response.message;
                    window.$("#loginPopupFields").removeClass("show");
                    window.$("#VerificationFields").addClass("show");
                    //setTimeout(() => (this.$refs.otp_1.focus()), 1000);
                    this.setTime(60);
                    this.resendcodeflg = true;
                    this.loading = false;
                    localStorage.setItem("address_set_type", "1")
                  }
                }
              }
            });
          }
        });
      }
      else {
        this.$validator.validateAll('emailaddresslogin').then(result => {
          if (result) {
            this.setTime(60);
            this.loading = true;
            this.loginbody.phone = this.emailaddress;
            this.$store.dispatch("register", this.loginbody).then(response => {
              if (response) {
                if (response.message != null && response.status == "0") {
                  this.errormessage = response.message;
                  this.loading = false;
                } else {
                  this.status = response.status;
                  if (response.status == "3") {
                    this.resetpassbody.phone = this.mobilenumber;
                    this.$store
                      .dispatch("resetpassword", this.resetpassbody)
                      .then(res => {
                        this.loading = false;
                        this.successmessage = JSON.parse(JSON.stringify(res)).message;
                        this.enablesucmessage = true;
                        setTimeout(() => (this.enablesucmessage = false), 3000);
                        this.enablesucmessage = false;
                        if (this.enablesucmessage == false) {
                          window.$("#loginPopupFields").removeClass("show");
                          window.$("#VerificationFields").addClass("show");
                          //setTimeout(() => (this.$refs.otp_1.focus()), 1000);
                          // this.setTime(60);
                          this.setTime(60);
                          this.resendcodeflg = true;
                        }
                      });
                  } else {
                    this.successmessage = response.message;
                    window.$("#loginPopupFields").removeClass("show");
                    window.$("#VerificationFields").addClass("show");
                    this.setTime(60);
                    this.resendcodeflg = true;
                    this.loading = false;
                  }
                }
              }
            });
          }
        });
      }
    },
    firebaseCustomerAuth() {
      var user_id = JSON.parse(localStorage.getItem("user")).user_id;
      var email = user_id + '.customer@gbazaar.com';
      var password = "login12*";
      firebase.auth().signInWithEmailAndPassword(email, password).then((userCredential) => {
        var user = userCredential.user;
        this.firebaseuuid = user.uid;
      }).catch((error) => {
        console.log(error);
      });
    }
  }
};
</script>