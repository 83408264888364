<template>
  <footer :style="{ backgroundColor: footer_color }" class="d-md-block d-none">
    <div class="top-footer">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="logo">
              <a href="/"><img src="../assets/images/logo-white.svg" alt="logo" class="d-block w-100"></a>
            </div>
            <div class="details mt-4" :style="footerFontColor">
              <p>Customer Care: (Hours: 7Am - 12Pm) <br>
                <a href="mailto:support@gharbazaar.com">support@gharbazaar.com</a>
              </p>
            </div>
            <ul class="social-media-links row list-unstyled mx-0">
              <li>
                <a href="https://www.Facebook.com/GharBazaarDFW" target="_blank">
                  <img src="..\assets\images\svgs\icons-22.svg">
                </a>
              </li> &nbsp; &nbsp;
              <li>
                <a href="https://www.instagram.com/gharbazaarusa/" target="_blank">
                  <img src="../assets/images/svgs/icons-23.svg">
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col-sm-6 col-xl-3">
                <div class="links">
                  <h3 :style="footerFontColor">Categories</h3>
                  <ul class="list-unstyled footer-links">
                    <li v-for="(category, index) in categories" v-bind:key="index">
                      <a v-if="index == 0 || index <= 5" href="javascript:void(0)"
                        @click="getStoreByCategory(category)">
                        {{ category.shop_category_name }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="links">
                  <h3>&nbsp;</h3>
                  <ul class="list-unstyled footer-links">
                    <li v-for="(category, index) in categories" v-bind:key="index">
                      <a v-if="index >= 6 && index <= 11" href="javascript:void(0)"
                        @click="getStoreByCategory(category)">
                        {{ category.shop_category_name }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="links">
                  <h3 :style="footerFontColor">Company</h3>
                  <ul class="list-unstyled footer-links">
                    <li v-if="whats_new_customer"><a href="javascript:void(0);" @click="openModal()">What's New
                      </a></li>
                    <li><router-link to="/newsletter">Sign Up For Newsletter</router-link></li>
                    <li><router-link to="/about-us">About Us</router-link></li>
                    <li><router-link to="/faq">FAQ</router-link></li>
                    <li><router-link to="/return-policy">Return Policy</router-link></li>
                    <li><router-link to="/return-center">GB Return Center</router-link></li>
                    <li v-if="is_exclusive_deal_offer == 1"><router-link to="/exclusive-deals">Exclusive Deals</router-link></li>
                    <li><router-link to="/UniversityProgram">Student Discount Program</router-link></li>
                    <li><router-link to="/feedback">Feedback</router-link></li>
                    <li><router-link to="/tou">Terms of Use</router-link></li>
                    <li><a href="javascript:void(0);" @click="openContactUs()">Contact Us</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="links">
                  <h3 :style="footerFontColor">Mobile App</h3>
                  <ul class="list-unstyled footer-links app-store">
                    <li>
                      <a :href="iosappurl" target="_blank" style="width: 130px"><img
                          src="../assets/images/svgs/apple-store.svg" alt="apple-store"></a>
                    </li>
                    <li>
                      <a :href="androidappurl" target="_blank" style="width: 130px"><img
                          src="../assets/images/svgs/play-store.svg" alt="play-store"></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom text-white text-center">
      <div class="container">
        <p class="mb-0" :style="footerFontColor">{{ footer_copyright_text }}</p>
      </div>
    </div>

  </footer>
</template>
<script>
import helper from "../store/helper";
import { bus } from ".././main"
export default {
  name: 'Footer',
  props: {
    categories: Array
  },
  mixins: [helper],
  data() {
    return {
      iosappurl: '',
      androidappurl: '',
      is_business_model: 0,
      customer_image: "",
      whats_new_customer: "",
      store_id: '1',
      category: {
        category_id: '',
        category_name: '',
      },
      footer_color: '',
      footer_copyright_text: '',
      store_whats_new_customer: "",
      whats_new: "",
      store_details: "",
      is_exclusive_deal_offer: "",
    };
  },
  mounted() {
    this.is_business_model = localStorage.getItem("is_business_model");
    this.iosappurl = localStorage.getItem("customerappurlios");
    this.androidappurl = localStorage.getItem("customerappurlandroid");
    this.footer_color = localStorage.getItem("footer_backgroundcolor");
    this.footer_copyright_text = localStorage.getItem("footer_copyright_text");
    this.customer_image = JSON.parse(localStorage.getItem("global_settings"));
    this.is_exclusive_deal_offer = this.customer_image?.is_exclusive_deal_offer
    bus.$on("shop-details", data => {
      this.store_details = JSON.parse(data);
      this.store_customer_image = this.store_details?.whats_new_customer
    });
    this.store_whats_new_customer = localStorage.getItem("whats_new_customer")
    this.whats_new = this.store_whats_new_customer
   

    if (this.whats_new) {
      this.whats_new_customer = this.store_whats_new_customer;
    } else {
      this.whats_new_customer = this.store_customer_image?.whats_new_customer;
    }

  },
  methods: {
    getStoreByCategory(category) {
      this.category.category_id = category.store.category_id;
      this.category.category_name = category.shop_category_name;
      localStorage.setItem("selected-category", JSON.stringify(this.category));
      this.store_id = localStorage.getItem("store_id");
      window.location = '/shopdetail?storeid=' + this.store_id;
    },
    openContactUs() {
      window.$("#ContactUsModal").modal("show");
    },
    openModal() {
      if (this.whats_new != '' && !this.store_customer_image) {
        document.getElementsByClassName('whats-new-imagessss')[0].attributes[0].value = this.whats_new;
      }
      else {
        document.getElementsByClassName('whats-new-imagessss')[0].attributes[0].value = this.store_customer_image;
      }
      window.$("#WhatsNew").modal("show");
    }
  }
}
</script>