<template>
  <div ref="default_layout">
    <div class="main-content">
      <Header :loading="loading" :userprofile="userprofile" ref="header"></Header>
      <slot/>
      <Footer :categories="storecategory" v-if="storecategory!=null"></Footer>
      <login></login>
      <gift-voucher></gift-voucher>
      <contact-us></contact-us>      
      <whatsnew v-if="current_route_name != 'signup'"></whatsnew>
      <locationselector v-if="current_route_name != 'signup'"></locationselector>
    </div>
    <div class="mob-sticky-footer d-md-none">
      <b-collapse class="taggle-search" id="BTogglesearchbar">
          <form class="form-inline shop-searchbar" @submit.prevent="postSearchnew">
              <div class="form-group col-12">
                <label for="searchbar-footer" class="sr-only">search</label>
                <input type="text" class="form-control" id="searchbar-footer" :placeholder="(is_business_model == 3) ? 'Search Bazaar' : 'Search for item / Category / Store'" ref="header_search" v-model="searchqry">
              </div>    
          </form>
      </b-collapse>
      <div class="container">
        <div class="row">
          <div class="col-3">
            <div class="sticky-footer-wrap">
              <a href="/" :style="(current_route_name == 'home') ? 'color:'+buttonColor+'' : 'color:#959595'">
                <svg v-if="current_route_name == 'home'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 54.944 48.978" enable-background="new 0 0 54.944 48.978" xml:space="preserve">
                  <g>
                    <polygon fill="currentColor" points="27.472,2.125 48.484,15.571 47.275,46.011 46.775,47.511 34.275,47.511 32.775,45.386 
                      33.152,32.057 31.775,30.011 28.527,27.764 25.402,28.386 23.152,30.386 21.902,33.511 21.902,37.261 21.902,43.136 21.902,45.636 
                      21.902,47.511 7.402,47.511 7.402,16.261"/>
                    <g>
                      <g>
                        <g>
                          <path fill="currentColor" d="M54.359,19.344L28.316,0.276c-0.502-0.368-1.186-0.368-1.688,0L0.583,19.344
                            c-0.635,0.466-0.773,1.359-0.309,1.996c0.467,0.636,1.359,0.773,1.996,0.309l5.818-4.263L27.472,3.198l25.201,18.451
                            c0.252,0.186,0.549,0.276,0.842,0.276c0.439,0,0.873-0.204,1.153-0.585C55.134,20.704,54.996,19.81,54.359,19.344z"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path fill="currentColor" d="M47.458,15.136c-0.789,0-1.428,0.64-1.428,1.428v29.559h-11.42V33.718c0-3.936-3.203-7.138-7.139-7.138
                            c-3.937,0-7.139,3.202-7.139,7.138v12.404H8.914V16.564c0-0.788-0.64-1.428-1.428-1.428c-0.789,0-1.428,0.64-1.428,1.428V47.55
                            c0,0.788,0.639,1.428,1.428,1.428h14.275c0.748,0,1.363-0.579,1.422-1.316c0.003-0.034,0.004-0.069,0.004-0.111V33.718
                            c0-2.361,1.92-4.282,4.285-4.282c2.363,0,4.283,1.921,4.283,4.282V47.55c0,0.042,0.002,0.077,0.006,0.109
                            c0.056,0.737,0.67,1.318,1.422,1.318h14.275c0.789,0,1.428-0.64,1.428-1.428V16.564C48.886,15.776,48.248,15.136,47.458,15.136z
                            "/>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <svg v-else version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 63.149 63.548" enable-background="new 0 0 63.149 63.548" xml:space="preserve">
                  <path fill="currentColor" d="M62.802,28.997L31.574,0L0.35,28.997c-0.443,0.41-0.467,1.1-0.059,1.54c0.408,0.439,1.102,0.465,1.541,0.057
                  l3.613-3.355v36.31h17.42h17.42h17.422V27.236l3.613,3.355c0.209,0.195,0.477,0.291,0.742,0.291c0.293,0,0.584-0.116,0.795-0.348
                  C63.27,30.097,63.242,29.407,62.802,28.997z M25.043,61.371V43.947c0-3.599,2.93-6.531,6.531-6.531c3.604,0,6.535,2.933,6.535,6.531
                  v17.424H25.043z M55.531,61.371H40.285V43.947c0-4.799-3.908-8.707-8.711-8.707c-4.801,0-8.709,3.908-8.709,8.707v17.424H7.623
                  V25.213L31.574,2.971l23.957,22.242V61.371z"/>
                </svg>
                <p>Home</p>
              </a>
            </div>
          </div>
          <div class="col-3">
            <div class="sticky-footer-wrap">
              <a href="javascript:void(0)"  @click="searchToggle" :style="(current_route_name == 'search') ? 'color:'+buttonColor+'' : 'color:#959595'">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 54.098 54.107" enable-background="new 0 0 54.098 54.107" xml:space="preserve">
                  <g>
                    <g>
                      <path fill="currentColor" d="M53.629,51.349L40.478,38.199c3.531-4.054,5.665-9.345,5.665-15.131C46.143,10.341,35.789,0,23.07,0
                        C10.342,0,0,10.351,0,23.068c0,12.72,10.354,23.073,23.07,23.073c5.784,0,11.075-2.135,15.125-5.662L51.35,53.629
                        c0.311,0.312,0.733,0.479,1.142,0.479c0.409,0,0.823-0.154,1.138-0.479C54.253,53.006,54.253,51.972,53.629,51.349L53.629,51.349z
                         M3.227,23.068c0-10.94,8.902-19.829,19.835-19.829c10.937,0,19.828,8.9,19.828,19.829c0,10.933-8.892,19.847-19.828,19.847
                        C12.129,42.915,3.227,34.012,3.227,23.068L3.227,23.068z M3.227,23.068"/>
                    </g>
                  </g>
                </svg>
                <p>Search</p>
              </a>
            </div>
          </div>
          <div class="col-3">
            <div class="sticky-footer-wrap">
              <a href="javascript:void(0)" @click="getMyOrders()" :style="(current_route_name == 'profile') ? 'color:'+buttonColor+'' : 'color:#959595'">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 40.153 48.862" enable-background="new 0 0 40.153 48.862" xml:space="preserve">
                  <g>
                    <g>
                      <path fill="currentColor" d="M30.896,17.507H9.257c-0.479,0-0.865,0.387-0.865,0.865s0.387,0.865,0.865,0.865h21.639
                        c0.479,0,0.867-0.387,0.867-0.865S31.374,17.507,30.896,17.507z"/>
                      <path fill="currentColor" d="M9.257,12.313h8.656c0.478,0,0.865-0.388,0.865-0.866s-0.388-0.865-0.865-0.865H9.257
                        c-0.479,0-0.865,0.387-0.865,0.865S8.778,12.313,9.257,12.313z"/>
                      <path fill="currentColor" d="M30.896,24.432H9.257c-0.479,0-0.865,0.387-0.865,0.865s0.387,0.866,0.865,0.866h21.639
                        c0.479,0,0.867-0.388,0.867-0.866S31.374,24.432,30.896,24.432z"/>
                      <path fill="currentColor" d="M30.896,31.356H9.257c-0.479,0-0.865,0.387-0.865,0.866c0,0.479,0.387,0.865,0.865,0.865h21.639
                        c0.479,0,0.867-0.387,0.867-0.865C31.763,31.743,31.374,31.356,30.896,31.356z"/>
                      <path fill="currentColor" d="M30.896,38.282H9.257c-0.479,0-0.865,0.386-0.865,0.865c0,0.479,0.387,0.865,0.865,0.865h21.639
                        c0.479,0,0.867-0.387,0.867-0.865C31.763,38.668,31.374,38.282,30.896,38.282z"/>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path fill="currentColor" d="M0,34.425v-2.571v-6.252V23.03v-6.251V14.21V7.958V3.125C0,1.402,1.403,0,3.126,0h33.902
                            c1.723,0,3.125,1.402,3.125,3.125v42.612c0,1.724-1.402,3.125-3.125,3.125H3.126C1.403,48.862,0,47.461,0,45.737v-5.062V34.425z
                             M2.489,38.189v7.261c0,0.449,0.365,0.815,0.813,0.815h33.315c0.448,0,0.812-0.366,0.812-0.815V3.413
                            c0-0.449-0.364-0.814-0.812-0.814H3.303c-0.448,0-0.813,0.365-0.813,0.814c0,0,0,5.277,0,7.036v1.631c0,1.769,0,7.071,0,7.071
                            v1.631v7.072v1.631v7.073V38.189z"/>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <p>My Orders</p>
              </a>
            </div>
          </div>
          <div class="col-3">
            <div class="sticky-footer-wrap sticky-cart">
              <a href="javascript:void(0)" @click="getCartDetails()" :style="(current_route_name == 'cart') ? 'color:'+buttonColor+'' : 'color:#959595'">
                <span v-if="getcartlngth != 0" class="notification" :style="'background-color:'+buttonColor">{{getcartlngth}}</span>
                <span v-else class="notification">12</span>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 46.932 50.505" enable-background="new 0 0 46.932 50.505" xml:space="preserve">
                  <g>
                    <path fill="currentColor" d="M13.376,33.254h27.828c3.155,0,5.728-2.573,5.728-5.729V15.816c0-0.011,0-0.031,0-0.041
                      c0-0.032,0-0.053,0-0.084c0-0.021,0-0.041-0.01-0.062c0-0.021-0.01-0.052-0.01-0.072S46.9,15.515,46.9,15.495
                      c-0.009-0.021-0.009-0.043-0.02-0.073c-0.011-0.021-0.011-0.042-0.02-0.062c-0.011-0.02-0.011-0.042-0.021-0.062
                      c-0.01-0.021-0.021-0.041-0.031-0.073c-0.01-0.02-0.021-0.041-0.031-0.051c-0.01-0.021-0.021-0.041-0.031-0.062
                      c-0.01-0.021-0.021-0.031-0.031-0.052s-0.031-0.042-0.041-0.063c-0.01-0.02-0.021-0.029-0.041-0.051
                      c-0.012-0.021-0.031-0.031-0.038-0.052c-0.015-0.021-0.034-0.031-0.042-0.053c-0.015-0.02-0.034-0.03-0.042-0.041
                      c-0.023-0.021-0.034-0.031-0.055-0.052c-0.021-0.01-0.029-0.031-0.052-0.041c-0.021-0.012-0.041-0.032-0.061-0.041
                      c-0.022-0.011-0.032-0.021-0.055-0.032c-0.021-0.011-0.041-0.021-0.062-0.041c-0.021-0.011-0.041-0.021-0.062-0.032
                      c-0.021-0.01-0.044-0.02-0.062-0.03c-0.021-0.011-0.043-0.021-0.063-0.031c-0.021-0.01-0.04-0.01-0.062-0.021
                      c-0.021-0.012-0.052-0.021-0.073-0.021c-0.021,0-0.041-0.01-0.051-0.01c-0.033-0.011-0.052-0.011-0.084-0.011
                      c-0.011,0-0.021-0.01-0.042-0.01l-35.27-4.869v-4.92c0-0.051,0-0.104-0.01-0.145c0-0.011,0-0.021-0.011-0.041
                      c0-0.031-0.011-0.063-0.011-0.094c-0.01-0.031-0.01-0.053-0.022-0.084c0-0.02-0.009-0.03-0.009-0.052
                      c-0.01-0.03-0.021-0.062-0.031-0.094c0-0.01-0.01-0.03-0.01-0.041c-0.012-0.031-0.021-0.052-0.043-0.083
                      c-0.01-0.011-0.01-0.031-0.02-0.041c-0.012-0.021-0.021-0.041-0.043-0.063c-0.009-0.02-0.02-0.03-0.031-0.051
                      c-0.008-0.021-0.021-0.031-0.03-0.052c-0.01-0.021-0.031-0.042-0.041-0.062c-0.01-0.011-0.021-0.021-0.03-0.03
                      c-0.021-0.022-0.042-0.043-0.063-0.063c-0.011-0.011-0.021-0.021-0.03-0.031c-0.021-0.021-0.041-0.041-0.074-0.062
                      c-0.009-0.01-0.031-0.021-0.04-0.032c-0.021-0.02-0.041-0.03-0.062-0.052C9.815,3.443,9.785,3.423,9.764,3.412
                      c-0.012-0.01-0.021-0.01-0.031-0.021C9.688,3.37,9.639,3.35,9.598,3.329L1.948,0.111C1.231-0.19,0.41,0.143,0.111,0.859
                      C-0.19,1.575,0.143,2.395,0.857,2.696l6.789,2.864v6.353v1.008v7.722v6.902v8.719c0,2.906,2.179,5.314,4.992,5.678
                      c-0.509,0.852-0.81,1.849-0.81,2.907c0,3.124,2.543,5.656,5.655,5.656c3.115,0,5.658-2.543,5.658-5.656
                      c0-1.039-0.279-2.024-0.777-2.854h12.602c-0.498,0.842-0.779,1.815-0.779,2.854c0,3.124,2.543,5.656,5.656,5.656
                      c3.114,0,5.657-2.543,5.657-5.656c0-3.114-2.543-5.657-5.657-5.657H13.376c-1.621,0-2.927-1.317-2.927-2.928v-3.799
                      C11.303,32.964,12.307,33.254,13.376,33.254z M20.352,44.838c0,1.578-1.287,2.854-2.855,2.854c-1.566,0-2.854-1.287-2.854-2.854
                      s1.288-2.854,2.854-2.854C19.064,41.983,20.352,43.26,20.352,44.838z M42.707,44.838c0,1.578-1.285,2.854-2.852,2.854
                      c-1.57,0-2.855-1.287-2.855-2.854s1.285-2.854,2.855-2.854C41.422,41.983,42.707,43.26,42.707,44.838z M41.204,30.452H13.376
                      c-1.621,0-2.927-1.318-2.927-2.928v-6.902v-7.723v-0.519l33.682,4.64v10.493C44.131,29.144,42.812,30.452,41.204,30.452z"/>
                  </g>
                  </svg>
                <p>Cart</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Header from "../components/Header";
import Login from "../components/user/Login";
import WhatsNew from "../components/home/WhatsNew";
import LocationSelector from "../components/home/LocationSelector";
import Footer from "../components/Footer";
import "owl.carousel";
import { bus } from "../main";
import GiftVoucher from "../components/gift-voucher/GiftVoucher";
import ContactUs from "../components/pages/ContactUs";
import localconfig from "../global_settings.json";
import firebase from 'firebase';
export default {
  name: "Defaultlayout",
  data() {
    return {
      loading: true,
      userprofile: null,
      storedetails: null,
      storecategory: null,
      searchqry: "",
      current_route_name: "",
      buttonColor: "",
      custprodctdetail: {
        user_id: "",
        cart_type: "",
        timezone: ""
      },
      getcartdata: "",
      getcartlngth: 0,
      is_business_model: 0,
      freshchat: {
        user_id: "",
        freshchat_restore_id: "",
      },
      permission_granted:"",
      fcm_token:""
    };
  },
  computed: {
    ...mapGetters(["storewise_details"])
  },
  components: {
    Header,
    'login': Login,
    'whatsnew':WhatsNew,
    'locationselector': LocationSelector,
    'gift-voucher': GiftVoucher,
    'contact-us' : ContactUs,
    Footer
  },
  created(){
    this.requestPermission();
  },
  mounted() {
    if(localStorage.getItem("user") != null){
      //this.initPostLoginFreshChat();
    }

    bus.$on("globalsetting",data => {
    if(Object.keys(data).length != 0){
    this.is_business_model = JSON.parse(data).business_model;
    } else {
    this.is_business_model = localconfig.business_model;
    }
    });
    this.current_route_name = this.$route.name;
    this.buttonColor = localStorage.getItem("button_default_color");
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
      this.getCart();
    } else {
      this.userprofile = null;
    }
    bus.$on("changeIt", data => {
      this.userprofile = data;
      window.$("#login-form").modal("hide");
    });
    bus.$on("logout", data => {
      localStorage.removeItem("user");
      this.userprofile = data;
    });
    bus.$on("getcartlength", data => {
      this.getcartlngth = data;
    });
    if (localStorage.getItem("store_categories") != null) {
      this.storecategory = JSON.parse(localStorage.getItem("store_categories"));
    } else {
      this.getCityWiseDashboard();
    }
  },
  methods: {
    getCityWiseDashboard() {
      this.loading = true;
      var lat = '23.0421945', lng = '72.5402261';
      if(localStorage.getItem("location_details") != null){
        if(JSON.parse(localStorage.getItem("location_details")).lat != null && JSON.parse(localStorage.getItem("location_details")).lng != null){
            lat = JSON.parse(localStorage.getItem("location_details")).lat;
            lng = JSON.parse(localStorage.getItem("location_details")).lng;
        }
      }
      var prelogin_pincode = localStorage.getItem("config_customer_pincode");
      var prelogin_area_id = localStorage.getItem("config_area_id");
      var postlogin_pincode = localStorage.getItem("user")!=null ? JSON.parse(localStorage.getItem("user")).zipcode : '';
      var postlogin_area_id = localStorage.getItem("user")!=null ? JSON.parse(localStorage.getItem("user")).area_id : '';
      var customer_location_type = localStorage.getItem("customer_location_type");
      localStorage.setItem("is_business_model",3);
      let bannerbody = {
        user_id: (this.userprofile != null) ? this.userprofile.user_id : "0",
        lat: lat,
        long: lng,
        is_demo_app: localStorage.getItem("is_business_model") == 3 ? 1 : 1,
        area_id: (this.userprofile != null) ? (customer_location_type == 2 && postlogin_area_id!=null? parseInt(postlogin_area_id):'') : (customer_location_type == 2 && prelogin_area_id!=null? parseInt(prelogin_area_id):''),
        pincode: (this.userprofile != null) ? (customer_location_type == 3 && postlogin_pincode!=null? parseInt(prelogin_pincode):'') : (customer_location_type == 3 && prelogin_pincode!=null? parseInt(prelogin_pincode):''),
        is_mobile: 0
      };

      this.$store.dispatch("getBanners", bannerbody).then(response => {
        this.loading = false;
        this.storedetails = JSON.parse(JSON.stringify(response.data));
        if(this.storedetails.status == 1) {
          if(this.is_business_model == 3){
            this.storecategory = this.storedetails.data.footer_category;
          } else {
            this.storecategory = this.storedetails.data.category;
          }                                                                                                                                                           
        }
        bus.$emit('citi_wise_dashboard', this.storedetails)
      });
    },
    getCart() {
      this.custprodctdetail.user_id = (localStorage.getItem("user") != null)
            ? JSON.parse(localStorage.getItem("user")).user_id
            : "0";
      this.custprodctdetail.cart_type = 1;
      this.custprodctdetail.timezone = "+6";
      this.$store
        .dispatch("getcartdetail", this.custprodctdetail)
        .then(response => {
          this.getcartdata = JSON.parse(JSON.stringify(response));
          if(this.getcartdata != null && this.getcartdata.status != 0){
            this.getcartlngth = this.getcartdata.data.cart_details.quantity;
            bus.$emit("getcartlength", this.getcartlngth);
          }
        });
    },
    getCartDetails(){
      if(localStorage.getItem("user") == null){
        this.openLoginfrm();
      } else {
        document.body.classList.remove('sidebar-open');
        this.$router.push("/cart");
      }
    },
    getMyOrders(){
      if(localStorage.getItem("user") == null){
        this.openLoginfrm();
      } else {
        document.body.classList.remove('sidebar-open');
        this.$router.push("/profile#my-order");
      }
    },
    openLoginfrm() {
      localStorage.setItem("LastUrlBeforeLogin",window.location.href);      
      window.$("#login-form").modal("show");
      window.$("#VerificationFields").removeClass("show");
      window.$("#SignupFields").removeClass("show");
      window.$("#ChangePassword").removeClass("show");
      window.$("#loginPopupFields").addClass("show");
    },
    searchToggle(){
      document.body.classList.remove('sidebar-open')
      this.current_route_name = 'search';
      setTimeout(() => (this.$refs.header_search.focus()), 1000);
      window.$("#BTogglesearchbar").is(':visible')?window.$("#BTogglesearchbar").hide():window.$("#BTogglesearchbar").show();
    },
    postSearchnew() {
      if(this.searchqry != ""){
        if(this.is_business_model == 3){
          localStorage.setItem("home-search", this.searchqry);
          this.store_id = localStorage.getItem("store_id");
          this.$router.push({ path: `/shopdetail?storeid=${this.store_id.toString()}` });
        } else {
          localStorage.setItem("searchqry", this.searchqry);
          this.$router.push({ name: "search" });
        }
      }
    },
    initPostLoginFreshChat(){
      var vm = this;
      var restoreId = (localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).freshchat_restore_id : "0";
      window.fcWidget.init({
        config: {
           cssNames: {
              widget: "custom_fc_frame"
           }
        },
        token: "90b097a6-6cbd-4c8f-83da-9eb5eb397a74",
        host: "https://wchat.in.freshchat.com",
        externalId: (localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).user_id : "0",
        restoreId: restoreId ? restoreId : null,
        firstName: (localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).name : "",
        lastName: "",
        email: (localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).email : "",
        phone: (localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).phone : "",
      });

      window.fcWidget.user.get(function(resp) {
        var status = resp && resp.status;
        if (status !== 200) {
          
          window.fcWidget.user.setProperties({
            firstName: (localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).name : "", // user's first name
            lastName: "", // user's last name
            email: (localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).email : "", // user's email address
            phone: (localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).phone : "", // phone number without country code
          });

          window.fcWidget.on('user:created', function(resp) {
            var status = resp && resp.status,
                data = resp && resp.data;
            if (status === 200) {
              if (data.restoreId) {
                vm.updateFreshChatRestoreId(data.restoreId);
              }
            }
          });
        }
      });
    },
    updateFreshChatRestoreId(freshchat_restore_id){
      this.freshchat.user_id = (this.userprofile != undefined) ? this.userprofile.user_id : "0";
      this.freshchat.freshchat_restore_id = freshchat_restore_id;
      this.$store
        .dispatch("updateFreshChat", this.freshchat)
        .then(response => {
          var result = JSON.parse(JSON.stringify(response));
          if (result.status == "1") {
            var user_details = JSON.parse(localStorage.getItem("user"));
            user_details.freshchat_restore_id = freshchat_restore_id;
            localStorage.setItem("user",JSON.stringify(user_details));
          }
        });
    },
    async requestPermission(){
      try {
        const permission = await Notification.requestPermission();
        this.permission_granted = permission === 'granted'
        if(this.permission_granted == true){
          this.getIdToken();
        }
      } catch(e){
        console.log(e)
      }
    },
    async getIdToken(){
      let current_token
      try {
        const messaging = firebase.messaging();
        messaging.getToken().then((token) => {
          current_token = token;
          if(current_token){
            this.fcm_token = current_token;
            localStorage.setItem("fcm_token", this.fcm_token);
            this.startMessageListner();
            //this.startTokenRefreshListner();
          } else {
            console.log('no instance id token available. Request permission to generate one.')
            const fcmToken = localStorage.getItem("fcm_token");
            if(fcmToken != null){
              localStorage.removeItem("fcm_token")
            }
            this.fcm_token = '';
          }
        })
      } catch(e){
        console.error('An error occured while retriving token',e)
        this.fcm_token = '';
      }
    },
    startMessageListner(){
      firebase.messaging().onMessage((payload) => {
        alert('12')
        console.log("Message Recevid",payload)
        const notification_option = {
            body: payload.notification.body,
            icon: payload.notification.icon
        };

        if(Notification.permission == 'granted'){
          var notification = new Notification(payload.notification.title, notification_option);
          notification
        }
      })
    },
    startTokenRefreshListner(){
      this.$fire.messaging.onTokenRefresh(async () =>{
        try {
          const refresh_token = await this.$fire.messaging.getToken();
          this.fcm_token = refresh_token;
        } catch(e){
          console.log('unable to retrive refreshed token',e);
        }
      })
    }
  },
};
</script>