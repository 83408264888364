<template>
  <div
    class="modal fade"
    id="GiftVoucherModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="GiftVoucherModalLabel"
    aria-hidden="true"
    ref="gift_voucher_form"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <form class="payment-form" method="post"  @submit.prevent="saveGiftVoucher">
          <div class="modal-body">
            <h2 class="page-heading text-center">Gift Voucher</h2>
            <div class="bottom-section1">
              <div class="row" v-show="false">
                <div class="col-md-12 mb-6 billing_address">
                  <label :style="textcolor" for="billing_address">Billing Address</label>
                  <select
                    class="form-control"
                    name="selectbillingaddress"
                    id="selectbillingaddress"
                    v-model="billing_address_id"
                  >
                    <option
                      v-for="(addrsdetl,index) in getaddressdetails.data"
                      :value="addrsdetl.customer_address_id"
                      v-bind:key="index"
                    >{{getFilteredUserAddress(addrsdetl)}}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label :style="textcolor" for="voucher_amount">Gift Voucher Amount ({{currency_symbol}})</label>
                  <input 
                    type="tel" 
                    class="form-control" 
                    :class="{error: errors.has('gift_form.voucher_amount')}" 
                    id="voucher_amount" 
                    :placeholder="'Gift Voucher Amount ('+currency_symbol+')'" 
                    name="voucher_amount" 
                    min="0"
                    v-model="voucher_amount"
                    data-vv-scope="gift_form" 
                    v-validate="'required'"
                    >
                  <span v-show="errors.has('gift_form.voucher_amount')" class="help is-danger">Gift Voucher Amount is required</span>
                </div>
                <div class="col-md-6 mb-3">
                  <label :style="textcolor" for="recipient_number">Recipient Number</label>
                  <input 
                    type="tel" 
                    class="form-control" 
                    :class="{error: errors.has('gift_form.recipient_number')}" 
                    id="recipient_number" 
                    placeholder="Recipient Number" 
                    name="recipient_number" 
                    data-vv-scope="gift_form"
                    v-model="recipient_number" 
                    v-validate="'required|digits:10'"
                    maxlength="10"
                    >
                  <span v-show="errors.has('gift_form.recipient_number')" class="help is-danger">Recipient Number is required</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label :style="textcolor" for="recipient_name">Recipient Name</label>
                  <input 
                    type="text" 
                    class="form-control" 
                    :class="{error: errors.has('gift_form.recipient_name')}" 
                    id="recipient_name" 
                    placeholder="Recipient Name" 
                    name="recipient_name" 
                    data-vv-scope="gift_form"
                    v-model="recipient_name" 
                    v-validate="'required'"
                    >
                  <span v-show="errors.has('gift_form.recipient_name')" class="help is-danger">Recipient Name is required</span>
                </div>
                <div class="col-md-6 mb-3">
                  <label :style="textcolor" for="recipient_name">Validity Period</label>
                  <div class="row">
                      <div class="col-md-6 mb-3">
                        <input 
                          type="text" 
                          class="form-control" 
                          :class="{error: errors.has('gift_form.validity_from')}" 
                          id="validity_from" 
                          placeholder="From Date"
                          name="validity_from" 
                          data-vv-scope="gift_form"
                          v-model="validity_from" 
                          v-validate="'required'"
                          disabled
                          >
                      </div>
                      <div class="col-md-6 mb-3">
                        <input 
                          type="text" 
                          class="form-control" 
                          :class="{error: errors.has('gift_form.validity_to')}" 
                          id="validity_to" 
                          placeholder="To Date" 
                          name="validity_to" data-vv-scope="gift_form"
                          v-model="validity_to" 
                          v-validate="'required'"
                          disabled
                          >
                      </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="payment_method_type == 4">
                <div class="col-md-6 mb-3">
                  <label :style="textcolor" for="ccname">Name on card</label>
                  <input 
                    type="text" 
                    class="form-control" 
                    :class="{error: errors.has('gift_form.ccname')}" 
                    id="ccname" 
                    placeholder="Name on card" 
                    name="ccname" 
                    data-vv-scope="gift_form_card"
                    v-model="ccname" 
                    v-validate="'required'">
                  <span v-show="errors.has('gift_form.ccname')" class="help is-danger">Name on card is required</span>
                </div>
                <div class="col-md-6 mb-3">
                  <label :style="textcolor" for="ccnumber">Credit card number</label>
                  <input 
                    type="text" 
                    class="form-control" 
                    :class="{error: errors.has('gift_form.ccnumber')}" 
                    id="ccnumber" 
                    placeholder="Credit card number" 
                    name="ccnumber" 
                    data-vv-scope="gift_form_card"
                    v-model="ccnumber" 
                    v-validate="'required'"
                    v-cardformat:formatCardNumber
                    maxlength="19">
                  <span v-show="errors.has('gift_form.ccnumber')" class="help is-danger">Credit card number is required</span>
                </div>
              </div>
              <div class="row" v-if="payment_method_type == 4">
                <div class="col-md-6 mb-3">
                  <label :style="textcolor" for="ccexpiration">Expiration</label>
                    <input 
                      type="text" 
                      class="form-control" 
                      :class="{error: errors.has('gift_form.ccexpiration')}" 
                      id="ccexpiration" 
                      placeholder="MM / YYYY" 
                      name="ccexpiration" 
                      data-vv-scope="gift_form_card"
                      v-model="ccexpiration" 
                      v-validate="'required'"
                      v-cardformat:formatCardExpiry>
                  <span v-show="errors.has('gift_form.ccexpiration')" class="help is-danger">Expiration is required</span>
                </div>
                <div class="col-md-6 mb-3">
                  <label :style="textcolor" for="cccvv">CVV</label>
                  <input 
                    type="text" 
                    class="form-control col-md-3" 
                    :class="{error: errors.has('gift_form.cccvv')}" 
                    id="cccvv" 
                    placeholder="CVV" 
                    name="cccvv" 
                    data-vv-scope="gift_form_card"
                    v-model="cccvv" 
                    v-validate="'required'"
                    v-cardformat:formatCardCVC>
                    <span v-show="errors.has('gift_form.cccvv')" class="help is-danger">Security code is required</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label :style="textcolor" class="form-text text-muted">Gift Voucher is non-transferrable and can be only used by registered user (Recipient Number)</label>
                </div>
                <div class="col-md-12 ml-4">
                  <input
                    type="checkbox"
                    id="voucher_agree"
                    name="voucher_agree"
                    v-model="voucher_agree"
                    value="0"
                    v-validate="'required'"
                    data-vv-scope="gift_form"
                    class="custom-control-input"
                    :class="{error: errors.has('gift_form.voucher_agree')}"
                  >
                  <label class="custom-control-label" for="voucher_agree">I agree to the Gift Voucher terms</label>
                  <br>
                  <span v-show="errors.has('gift_form.voucher_agree')" class="help is-danger helperrmesg">Agree to Terms of Gift Voucher</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="submit" class="btn paynow button-style hoverEffect" :style="buttonColor">Pay Now</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { bus } from '../../main';
import helper from "../../store/helper";
export default {
  name: "GiftVoucher",
  mixins:[helper],
  data() {
    return {
      currency_symbol: "₹",
      userprofile: null,
      voucher_amount: '',
      recipient_number: '',
      recipient_name: '',
      validity_from: '',
      validity_to: '',
      voucher_agree: '',
      ccname: '',
      ccnumber: '',
      ccexpiration: '',
      cccvv: '',
      billing_address_id:'',
      getaddressdetails: [],
      getaddressstatus: [],
      profile: {
        user_id: ''
      },
      gift_voucher_data: {
        user_id: '',
        name: '',
        phone: '',
        amount: '',
        from_date: '',
        to_date: '',
        card_name: '',
        card_number: '',
        expiration_date: '',
        card_code: '',
        source: '1',
        billing_address_id:'',
        transaction_id:'',
      },
      show_billing_address:false,
      payment_method_type: 1,
      generatetransactionrequest : {
        user_id: "",
        payment_gateway_type: "",
        amount: "",
        call_back_url: "",
        source:1,
        card_number:'',
        expiration_date:'',
        card_code:'',
        card_name:'',
        billing_address_id:''
      }
    };
  },
  mounted() {
    this.currency_symbol =  (localStorage.getItem("currency_symbol") == null || localStorage.getItem("currency_symbol") == "null") ? "$":localStorage.getItem("currency_symbol");
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
      //this.getUserAddress()
    } else {
      this.userprofile = null;
    }
    var _this=this;
    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0){
        this.show_billing_address = JSON.parse(data).show_billing_address;
        _this.payment_method_type = JSON.parse(data).payment_gateway_type;
      } else{
        this.show_billing_address = JSON.parse(data).show_billing_address;
        _this.payment_method_type = JSON.parse(data).payment_gateway_type;
      }
    })
    window.$(this.$refs.gift_voucher_form).on("show.bs.modal", this.onModalShow);
    
    
  },
  methods: {
    getUserAddress() {
      this.profile.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.address_loading = true;
      this.user_address = '';
      this.user_address_alias = '';
      this.$store.dispatch("customeraddress", this.profile).then(response => {
        var result = JSON.parse(JSON.stringify(response));
        if(result.status == 1){
          var default_address = this.getDefaultAddress(result.data);
          this.user_address = this.getFilteredUserAddress(default_address[0]);
          this.user_address_alias = default_address[0].alias;
          this.billing_address_id = default_address[0].customer_address_id;
        }

        this.getaddressstatus = response.status;
        
        this.getaddressdetails = response;
        this.address_loading = false;
        
        return this.getaddressstatus;
      });
    },
    getDefaultAddress(user_address){
      return user_address.filter(
        function(elem) {
          if (elem.is_default == "1") return elem;
        }
      );
    },
    getFilteredUserAddress(user){
      var tmp_user_address = [];
      tmp_user_address.push(user.address, user.address2, user.area_name, user.city_name, user.zipcode);
      var filtered_user_address = tmp_user_address.filter(function (el) { return el; });
      
      if(filtered_user_address.length > 0){
        return filtered_user_address.join(', ');
      } else {
        return "";
      }
    },
    saveGiftVoucher() {
      this.payment_method_type = localStorage.getItem("payment_gateway_type")
      this.gift_voucher_data.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.gift_voucher_data.name = this.recipient_name;
      this.gift_voucher_data.phone = this.recipient_number;
      this.gift_voucher_data.amount = this.voucher_amount;
      this.gift_voucher_data.from_date = moment(this.validity_from).format('DD-MM-YYYY');
      this.gift_voucher_data.to_date = moment(this.validity_to).format('DD-MM-YYYY');
      this.gift_voucher_data.card_name = this.ccname;
      this.gift_voucher_data.card_number = this.ccnumber;
      this.gift_voucher_data.expiration_date = this.ccexpiration.trim();
      this.gift_voucher_data.card_code = this.cccvv;
      this.gift_voucher_data.source = '1';
      this.gift_voucher_data.billing_address_id = this.billing_address_id;

      localStorage.setItem("gift_voucher_transaction_request",JSON.stringify(this.gift_voucher_data))

      if(this.payment_method_type == "1" || this.payment_method_type == "2" || this.payment_method_type == "3"){
          this.$validator.validateAll('gift_form').then(result => {
            if (result) {
              this.generatetransactionrequest.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
              this.generatetransactionrequest.payment_gateway_type = this.payment_method_type;
              this.generatetransactionrequest.amount = this.voucher_amount;
              this.generatetransactionrequest.call_back_url=window.location.href+'#GiftVoucher';
              this.generatetransactionrequest.source=1;
              this.generatetransactionrequest.card_name = this.ccname;
              this.generatetransactionrequest.card_number = this.ccnumber;
              this.generatetransactionrequest.expiration_date = this.ccexpiration.trim();
              this.generatetransactionrequest.card_code = this.cccvv;
              this.generatetransactionrequest.billing_address_id = this.billing_address_id;

              this.$store
              .dispatch("generatetransactionrequest", this.generatetransactionrequest)
              .then(response => {
                if (response.status == 1) {
                  localStorage.setItem("paymenttransactionid",response.data.transaction_id);
                  localStorage.setItem("paymentmethodid",this.payment_method_type);
                  window.location.replace(response.data.transaction_url);
                }
                this.loading=false;
                return false;
              })
            }
          })
      } else {
        this.$validator.validateAll('gift_form').then(result => {
          if (result) {
            this.$validator.validateAll('gift_form_card').then(result => {
              if (result) {
                this.gift_voucher_data.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
                this.gift_voucher_data.name = this.recipient_name;
                this.gift_voucher_data.phone = this.recipient_number;
                this.gift_voucher_data.amount = this.voucher_amount;
                this.gift_voucher_data.from_date = moment(this.validity_from).format('DD-MM-YYYY');
                this.gift_voucher_data.to_date = moment(this.validity_to).format('DD-MM-YYYY');
                this.gift_voucher_data.card_name = this.ccname;
                this.gift_voucher_data.card_number = this.ccnumber;
                this.gift_voucher_data.expiration_date = this.ccexpiration.trim();
                this.gift_voucher_data.card_code = this.cccvv;
                this.gift_voucher_data.source = '1';
                this.gift_voucher_data.billing_address_id = this.billing_address_id;
                if(!this.show_billing_address){
                  this.gift_voucher_data.billing_address_id = "";
                }
              
                this.generatetransactionrequest.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
                this.generatetransactionrequest.payment_gateway_type = this.payment_method_type;
                this.generatetransactionrequest.amount = this.voucher_amount;
                this.generatetransactionrequest.call_back_url=window.location.href+'#GiftVoucher';
                this.generatetransactionrequest.source=1;

                this.generatetransactionrequest.card_name = this.ccname;
                this.generatetransactionrequest.card_number = this.ccnumber;
                this.generatetransactionrequest.expiration_date = this.ccexpiration.trim();
                this.generatetransactionrequest.card_code = this.cccvv;
                this.generatetransactionrequest.billing_address_id = this.billing_address_id;

                this.$store
                .dispatch("generatetransactionrequest", this.generatetransactionrequest)
                .then(response => {
                  if (response.status == 1) {
                    this.gift_voucher_data.transaction_id = response.data.transaction_id;
                    this.$store
                    .dispatch("managegiftvoucher", this.gift_voucher_data)
                    .then(response => {
                    window.$('#GiftVoucherModal').modal("hide");
                    if (response.status == 0) {
                      this.$toast.error(response.message);
                    } else {
                      this.$toast.success(response.message);
                    }
                    });
                  }
                  this.loading=false;
                  return false;
                })
              }
            })
          }
        });
      }
    },
    onModalShow(){
      if (this.userprofile != null) {
        this.getUserAddress()
      } 
      var date = new Date();
      this.voucher_amount = "";
      this.recipient_number = "";
      this.recipient_name = "";
      this.validity_from = moment(date).format('MM-DD-YYYY');
      this.validity_to = moment(date).add('years', 1).subtract(1, 'd').format('MM-DD-YYYY');
      this.voucher_agree = "";
      this.ccname = "";
      this.ccnumber = "";
      this.ccexpiration = "";
      this.cccvv = "";
      this.$validator.reset();
    },
  }
};
</script>