<template>
	<div class="modal fade whats-new" id="WhatsNew" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
		aria-hidden="true" data-backdrop="static">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<button type="button" class="close" aria-label="Close" @click="closeWhatsNewModal()">
					<span aria-hidden="true">&times;</span>
				</button>
				<div class="modal-body p-0">
					<img :src="whats_new_customer" class="d-block img-fluid w-100 whats-new-imagessss"
						v-if="whats_new_customer != ''">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import localconfig from "../../global_settings.json";
import { bus } from "../../main";
export default {
	name: 'WhatsNew',
	data: function () {
		return {
			global_settings: [],
			whats_new_customer: "",
			whats_new_image: "",
			store_details: "",
			store_customer_image: "",
			whats_new_visit: false
		}
	},
	mounted() {
		bus.$on("shop-details", data => {
			this.store_details = JSON.parse(data);
			this.store_customer_image = this.store_details.whats_new_customer
		});
		this.store_whats_new_customer = localStorage.getItem("whats_new_customer")
		var whats_new = this.store_whats_new_customer
		if((localStorage.getItem("location_details") == null || localStorage.getItem("location_details") == undefined) && window.location.pathname != '/order-summary' )
		{
			this.getGlobalSettings();
		}
		else if (whats_new != '' && !this.store_customer_image) {
			this.whats_new_customer = whats_new;
		}
		else if (this.store_customer_image) {
			this.whats_new_customer = this.store_customer_image;
		} 
	},
	methods: {
		closeWhatsNewModal() {
			var year = 1000 * 60 * 60 * 24 * 365;
			var expires = new Date((new Date()).valueOf() + year);
			document.cookie = "whats_new=" + this.whats_new_customer + ";expires=" + expires.toUTCString();
			window.$("#WhatsNew").modal("hide");
			localStorage.setItem('visited', 0);
		},
		async getGlobalSettings() {
			return this.$store.dispatch("getglobalsetng").then(response => {
				if (response.status == 1) {
					this.globalsettings = response.data;
					this.whats_new_customer = this.globalsettings.whats_new_customer;
					if (this.whats_new_customer != null) {
						if (this.whats_new_customer != '' && !(localStorage.getItem("whats_new_visit"))) {
							if (window.location.pathname == '/order-summary' || window.location.pathname == "/exclusive-deals") {
								window.$("#WhatsNew").modal("hide");
							}else{
								window.$("#WhatsNew").modal("show");
							}
						}
						this.whats_new_visit = true
						localStorage.setItem("whats_new_visit", this.whats_new_visit)
					}
				} else {
					this.whats_new_customer = localconfig.whats_new_customer;
				}
			})
		}
	}
}
</script>

<style>
#WhatsNew {
	z-index: 9999;
}
</style>