<template>
  <div
    class="modal fade address-popup edit-addressbook-popup"
    id="ContactUsModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ContactUsModalLabel"
    aria-hidden="true"
    data-backdrop="static" data-keyboard="false"
    ref="contact_us"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearData()">
          <span aria-hidden="true">&times;</span>
        </button>
        <form class="contactus-form available-option" method="post" :model="form" @submit.prevent="saveContactUs">
          <div class="modal-body">
            <h2 class="page-heading text-center">Contact Us</h2>
            <div class="bottom-section1">
              <div class="d-flex flex-wrap align-item-center w-100 contact-us" v-show="show_enquiry_type">
                <div class="form-row">
                  <div class="d-flex flex-wrap radio-dt general-enquiry">
                  <input 
                    type="radio" 
                    id="general_enquiry" 
                    name="general_enquiry" 
                    value="1"
                    v-model="contact_type">
                    <label for="general_enquiry">
                      <span>General Enquiry</span></label>
                  </div>
                </div>
                <div class="form-row" v-if="orderbody.user_id!=0">
                  <div class="d-flex flex-wrap radio-dt order-related">
                    <input 
                    type="radio" 
                    id="order_related" 
                    name="order_related" 
                    value="2" 
                    v-model="contact_type">
                    <label for="order_related"><span>Order Related</span></label>
                  </div>
                </div>
              </div>
              <div class="row" >
                <div class="col-md-6 mb-6" v-if="contact_type == 2">
                  <span v-if="(from_page == 'my-order' || from_page == 'order-detail') && contact_type == 2">
                    <label :style="textcolor" for="customer_order_id">Order Number *</label>
                    
                    <input 
                      type="text" 
                      class="form-control" 
                      id="customer_order_id" 
                      name="customer_order_id" 
                      v-model="customer_order_id" 
                      v-validate="contact_type == 2 ? 'required' : ''"
                      :class="{error: errors.has('customer_order_id')}"
                      disabled
                      >
                  </span>
                  {{customer_order_id}}
                  <span v-if="(from_page!='my-order' && from_page != 'order-detail')">
                    <label :style="textcolor" for="customer_order_id">Order Number *</label>
                    <select
                      class="form-control"
                      name="customer_order_id"
                      id="customer_order_id"
                      v-model="customer_order_id"
                      :disabled="!show_enquiry_type"
                      v-validate="contact_type == 2 ? 'required' : ''"
                      :class="{error: errors.has('customer_order_id')}"
                    >
                      <option value>Select Your Order</option>
                      <option
                        v-for="(ord,index) in getcustorder"
                        :value="ord.order_id"
                        v-bind:key="index"
                      >{{ord.order_no}} | {{ord.khata_date}}</option>
                    </select>
                    <span
                      v-show="errors.has('customer_order_id')"
                      class="help is-danger"
                    >Order number is required</span>                  
                  </span>
                </div>
                <div :class="contact_type == 2 ? 'col-md-6 mb-3' : 'col-md-12 mb-6'">
                  <label :style="textcolor" for="user_name">Name <span v-if="orderbody.user_id ==0">*</span></label>
                  <input 
                    type="text" 
                    class="form-control" 
                    id="user_name" 
                    placeholder="Name" 
                    name="user_name" 
                    v-model="user_name" 
                    v-validate="orderbody.user_id == 0 ? 'required': ''"
                    :class="{error: errors.has('user_name')}"
                    :disabled="orderbody.user_id != 0"
                    >
                  <span v-show="errors.has('user_name')" class="help is-danger">Enter your Name</span>
                </div>
              </div>
              <div class="row message-form-row">                
                <div class="col-md-6 mb-3">
                  <label :style="textcolor" for="mobile_number">Mobile Number <span v-if="orderbody.user_id ==0">*</span></label>
                  <input 
                    type="tel" 
                    class="form-control" 
                    :class="{error: errors.has('mobile_number')}" 
                    id="mobile_number" 
                    placeholder="Mobile Number" 
                    name="mobile_number" 
                    v-model="mobile_number" 
                    v-validate="'required|digits:10'"
                    maxlength="10"
                    :disabled="orderbody.user_id != 0"
                    >
                    <span v-show="errors.has('mobile_number')" class="help is-danger">Enter a valid mobile number</span>
                </div>

                <div class="col-md-6 mb-3">
                  <label :style="textcolor" for="email_address">Email Address <span v-if="orderbody.user_id ==0">*</span></label>
                  <input 
                    type="text" 
                    class="form-control" 
                    id="email_address" 
                    placeholder="Email Address" 
                    name="email_address" 
                    @keyup="validateEmail"
                    v-model="email_address" 
                    v-validate="orderbody.user_id == 0 ? 'required': ''"
                    :disabled="orderbody.user_id != 0"
                    >
                    <span class="help is-danger">{{validationmsg}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-12">
                  <label :style="textcolor" for="subject">Subject *</label>
                  <div class="row">
                      <div class="col-md-12 mb-12">
                        <input 
                          type="text" 
                          class="form-control" 
                          id="subject" 
                          placeholder="Subject"
                          name="subject" 
                          v-model="subject"
                          v-validate="'required'"
                          :class="{error: errors.has('subject')}"
                          >
                        <span v-show="errors.has('subject')" class="help is-danger">Subject is required</span>
                      </div>
                  </div>
                </div>
                <div class="col-md-12 mb-12 message-form-row">
                  <label :style="textcolor" for="message">Message *</label>
                  <div class="row">
                      <div class="col-md-12 mb-12">
                        <textarea
                          class="form-control" 
                          cols="50"
                          rows="3"
                          id="message" 
                          name="message"
                          v-model="message"
                          placeholder="Message"
                          v-validate="'required'"
                          :class="{error: errors.has('message')}"                          
                          >
                        </textarea>
                        <span v-show="errors.has('message')" class="help is-danger">Message is required</span>                        
                      </div>
                  </div>
                  <div class="col-md-12 mb-12 message-form-row">
                    <label for="robot" class="col-sm-2 col-form-label"></label>
                    <div class="col-sm-10" style="margin-left:-30px">                     
                      <vue-recaptcha ref="recaptcha"
                        @verify="onVerify" size="invisible" :sitekey="this.site_key" :loadRecaptchaScript="true">
                      </vue-recaptcha>
                      <span class="help is-danger">{{form.pleaseTickRecaptchaMessage}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="clearData()">Cancel</button>
            <button type="submit" class="btn paynow button-style hoverEffect" :style="buttonColor">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { bus } from '../../main';
import helper from "../../store/helper";
import VueRecaptcha from 'vue-recaptcha';
export default {
  name: "ContactUs",
  props: {
    from:{
      type:String
    },
    orderid: {
      type: Number
    }
  },
  components:{
    'vue-recaptcha': VueRecaptcha
  },
  mixins:[helper],
  data() {
    return {
      form:{
        robot:false,
        recaptchaVerified:false,
        pleaseTickRecaptchaMessage:''
      },
      contact_type: 1,
      email_address:'',
      message:'',
      subject:'',
      mobile_number:'',
      user_name:'',
      currency_symbol: "₹",
      inquiryrequest:{
        user_id:'',
        inquiry_type:'',
        order_id:'',
        subject:'',
        message:''
      },
      orderbody: {
        user_id: "",
      },
      getcustorder: null,
      userprofile: null,
      customer_order_id:'',
      getaddressdetails: [],
      getaddressstatus: [],
      profile: {
        user_id: ''
      },
      show_enquiry_type : true,
      from_page:"",
      validationmsg:"",
      site_key:"6LcaVuAcAAAAAE8Std4F2_ZgUG4cDOjP8qYGaWMz", //"6LdaewscAAAAAH2x3eR5O7_qzpXp86EbDXMJizvF",
      //eslint-disable-next-line
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    };
  },
  updated(){
    window.$('<style>.available-option .radio-dt input[type="radio"] ~ label:after { background-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.available-option .radio-dt input[type="radio"] ~ label:before { border-right-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.available-option .radio-dt input[type="radio"] ~ label:before { border-bottom-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.available-option .radio-dt input[type="radio"] ~ label:before { border-left-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.available-option .radio-dt input[type="radio"] ~ label:before { border-top-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.available-option .radio-dt input[type="radio"] ~ label:after { background-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    
  },
  mounted() {
    this.currency_symbol =  (localStorage.getItem("currency_symbol") == null || localStorage.getItem("currency_symbol") == "null") ? "$":localStorage.getItem("currency_symbol");
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
      //this.getOrderList();
    } else {
      this.userprofile = null;
    }
    window.$(this.$refs.contact_us).on("show.bs.modal", this.onModalShow);
    var _this=this;
    bus.$on("showContactForm", data => {
      _this.customer_order_id = data.order_no;
      _this.from_page = data.from;
      _this.contact_type = data.inquiry_type;
    });
    
  },
  methods: {
    clearData(){
      this.user_name = '';
      this.mobile_number = '';
      this.email_address = '';
      this.message = '';
      this.subject = '';
    },
    onVerify(response){
      if(response){
        this.form.robot = true;
      }
    },
    validateEmail(){
      if (!this.reg.test(this.email_address)) {
        this.validationmsg = 'Enter a valid email';
      } else {       
        this.validationmsg = '';
      }     
    },
     async getOrderList() {
      this.loading = true;
      this.orderbody.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.$store
        .dispatch("getcustomerorderlist", this.orderbody)
        .then(response => {
          if (response.status != 0) {
            this.getcustorder = response.data;
          }
          this.loading = false;
        });
    },
    getDefaultAddress(user_address){
      return user_address.filter(
        function(elem) {
          if (elem.is_default == "1") return elem;
        }
      );
    },
    getFilteredUserAddress(user){
      var tmp_user_address = [];
      tmp_user_address.push(user.address, user.address2, user.area_name, user.city_name, user.zipcode);
      var filtered_user_address = tmp_user_address.filter(function (el) { return el; });
      
      if(filtered_user_address.length > 0){
        return filtered_user_address.join(', ');
      } else {
        return "";
      }
    },
    saveContactUs() {
        this.$validator.validateAll().then(result => {
          if(!this.email_address){
            this.validationmsg = 'Enter a valid email';
          }
          if (result)
          {
          this.inquiryrequest.user_id = JSON.parse(localStorage.getItem("user"))!=undefined ? JSON.parse(localStorage.getItem("user")).user_id : 0;
          this.inquiryrequest.inquiry_type = this.contact_type;
          this.inquiryrequest.order_id = this.customer_order_id;
          this.inquiryrequest.subject = this.subject;
          this.inquiryrequest.message = this.message;
          if(this.inquiryrequest.user_id == 0){
            this.inquiryrequest.name = this.user_name;
            this.inquiryrequest.phone = this.mobile_number;
            this.inquiryrequest.email = this.email_address;
          }

          this.$store
          .dispatch("sendEnquiry", this.inquiryrequest)
          .then(response => {
            if (response.data.status == 1) 
            {
              this.$toast.success("Details Submitted Successfully");
              window.$("#ContactUsModal").modal("hide");
            }
            else{
              this.$toast.error(response.data.message);
              return false;
            }
          })
        }
      })

    },
    onModalShow(){
      this.subject = "";
      this.message = "";
      if(this.userprofile != null){
        this.getOrderList();
        if(this.from_page != 'my-order' && this.from_page != 'order-detail'){
          this.contact_type = 1;
          this.customer_order_id = "";
        }
        this.email_address = this.userprofile.email;
        this.mobile_number = this.userprofile.phone;
        this.user_name = this.userprofile.name;    
      } else {
        this.contact_type = 1;
        this.customer_order_id = "";  
        this.email_address = "";
        this.mobile_number = "";
        this.user_name = "";
      }
      this.$validator.reset();
    },
  }
};
</script>